<template>
<div id="TheEnd">
  <section>
    <div class="item">
      <div class="text">
        <div class="figcaption">
          <div class="vertical">Created with love & dedication</div>
          <h1>Slides</h1>
          <h3>I hope you have enjoyed the journey around my portfolio :)</h3>
          <p>This is just an example of the things I am capable of, and I would like to know your opinion on my
            portfolio</p>

          <p>
            I am available at any time for some screen sharing / meeting where I can show you all the things I have
            done, or if you want I can also meet up for a conversation.
          </p>

          <p style="margin-top: 20px">Hope to hear from you soon :)</p>
        </div>
        <div class="figure">21</div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  name: 'TheEnd',
  setup () {


  },
};
</script>

<style lang="stylus" scoped>
#TheEnd
  background white
  border-radius 20px
  position absolute
  top 70px
  right 70px
  bottom 70px
  left 70px
  font 500 32px 100px Averta

section
  width: 100%;
  padding: 2% 0

.item
  margin: 0 auto;

/* ---- UI Style ----- */
.item
  background-color: #fff;
  width: 450px;

.item .vertical
  font 400 20px AvertaStd
  color rgba(128, 128, 128, 0.16)
  transform: rotate(-90deg);
  position: absolute;
  left: -132px;
  top: 50%;

.item .text
  padding: 2em;
  position: relative;
  z-index: 1;

.item .text .figure
  font-size: 15em;
  margin: 0
  padding: 0
  color: rgba(0, 0, 0, 0.10);
  position: relative;
  z-index: 1;

.item .text .figcaption
  position: absolute;
  z-index: 10;
  margin-top: 2%;
  padding: 10%;

.item .text h1
  font-size: 50px;
  padding: 65px 0 30px;

.item .text h3
  padding: 50px 0 30px;
  color: rgba(0, 0, 0, 0.30);

.item .text a
  color: rgba(0, 0, 0, 0.70);

.item .text p
  color: rgba(0, 0, 0, 0.70);
  line-height: 22px;
  font-weight: 300;
  font-size: 13px;

/* ---- Responsive Code ---- */
@media (max-width: 768px)
  .item
    width: 300px;

</style>